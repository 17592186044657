<template>
  <label-cmpt
    :showTitle="'文章标签-单位统计'"
    :showRefreshDataCmp="false"
    :taskType="'cLabel'"
    :midType="'dept'"
    :labelType="'content'"
  ></label-cmpt>
</template>

<script>
// @ is an alias to /src
import _ from "lodash";

// import labelCmpt from "./labelCmpt.vue";
import labelCmpt from "./labelCmptNew.vue";

export default {
  name: "contentLabelDeptStats",
  components: {
    labelCmpt,
  },

  data() {
    return {};
  },
  methods: {},

  destroyed() {},
  async created() {},
};
</script>

<style scoped>
</style>
